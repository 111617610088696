import { lazy } from 'react';
import styled from 'styled-components';
import {
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  Link,
  useHistory,
} from 'react-router-dom';
import Layout, { Root, getContent } from '@mui-treasury/layout';
import CssBaseline from '@material-ui/core/CssBaseline';
import SideBar from './SideBar/SideBar';
import { Suspense } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
//Bank Screen
import React from 'react';
import { UserContext } from 'context/user/user';
import { OrganizationContext } from 'context/organization/organization';
import { Skeleton } from '@material-ui/lab';
import { Divider, IconButton } from '@material-ui/core';
import moment from 'moment';
import { Close } from '@material-ui/icons';
import { PATHNAMES } from 'lib/constants/pathnames';

const Banking = lazy(() => import('modules/Banking/banking'));
//404 Screen
const Notfoundpage = lazy(() => import('modules/404'));
const NoSubscription = lazy(() => import('modules/Plans/NoSubscription'));
const UpdateSubscription = lazy(() =>
  import('modules/Plans/UpdateSubscription'),
);
//Report Screen
const Report = lazy(() => import('modules/Reports/index'));
// const AuditLogs = lazy(() => import('modules/Report/audit-logs'));
// const Profit_loss = lazy(() => import('modules/Report/Component/Profit_loss'));
// const Budgetreport = lazy(() =>
//   import('modules/Report/Component/Budgetreport'),
// );
// const Employee_Report = lazy(() =>
//   import('modules/Report/Component/Employee_Report'),
// );
// const Incomebycustomer = lazy(() =>
//   import('modules/Report/Component/Incomebycustomer'),
// );
// const Expensesbyvendor = lazy(() =>
//   import('modules/Report/Component/Expensesbyvendor'),
// );
// const Customerledger = lazy(() =>
//   import('modules/Report/Component/Customerledger'),
// );
// const Agedrecievable = lazy(() =>
//   import('modules/Report/Component/Agedrecievable'),
// );
// const General_ledger = lazy(() =>
//   import('modules/Report/Component/Generalledger'),
// );
// const Balance_Sheet = lazy(() =>
//   import('modules/Report/Component/Balancesheet'),
// );
// const Cash_flow = lazy(() => import('modules/Report/Component/Cashflow'));
// const Trial_balance = lazy(() =>
//   import('modules/Report/Component/Trialbalance'),
// );

//Tax Screens
const TaxInfo = lazy(() => import('modules/Tax/taxInfo'));
const TaxCalender = lazy(() => import('modules/Tax/taxCalender'));
const TaxCalculation = lazy(() => import('modules/Tax/taxCalculations'));
const TaxFilingReturns = lazy(() => import('modules/Tax/taxFilingReturns'));
const TaxPayments = lazy(() => import('modules/Tax/taxPayment'));
const Pitcalculation = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Pitcalculation'),
);
const Citcalculation = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Citcalculation'),
);
const Whtcalculation = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Whtcalculation'),
);
const Payecalculation = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Payecalculation'),
);
const Cgtcalculationindv = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Cgtcalculationindv'),
);
const Cgtcalculationcomp = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Cgtcalculationcomp'),
);
const Vatgoods = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Vatgoods'),
);
const Vatservices = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Vatservices'),
);
const Vatasset = lazy(() =>
  import('modules/Tax/taxCalculations/calculations/Vatasset'),
);

//Accounting Screens
const Customer = lazy(() => import('modules/Accounting/customer/customer'));
const Journals = lazy(() => import('modules/Accounting/journals'));
const Invoice = lazy(() => import('modules/Invoice/index'));
const PayNow = lazy(() => import('modules/Invoice/view/PayNow'));
// const CreditNote = lazy(() => import('modules/Invoice/creditNote/CreditNote'));
const Inflow = lazy(() => import('modules/Accounting/inflow'));
const Outflow = lazy(() => import('modules/Accounting/outflow'));
const Quotation = lazy(() => import('modules/Accounting/quotation'));
const Transactions = lazy(() => import('modules/Accounting/transactions'));
// HR screens
const Employees = lazy(() => import('modules/HumanResources/Employees/index'));

const LeaveManagement = lazy(() =>
  import('modules/HumanResources/LeaveManagement'),
);
const Promotion = lazy(() => import('modules/HumanResources/Promotion'));
const Memo = lazy(() => import('modules/HumanResources/InternalMemo'));
const Payslip = lazy(() => import('modules/HumanResources/Payslip'));
const Suspension = lazy(() => import('modules/HumanResources/Suspension'));
const TransferManagement = lazy(() =>
  import('modules/HumanResources/TransferManagement'),
);
const PerformanceManagement = lazy(() =>
  import('modules/HumanResources/PerformanceManagement/index'),
);

const Dashboard = lazy(() => import('modules/Dashboard/dashboard'));

const TerminationManagement = lazy(() =>
  import('modules/HumanResources/TerminationManagement'),
);

const QueryManagement = lazy(() =>
  import('modules/HumanResources/QueryManagement'),
);

const PayrollManagement = lazy(() =>
  import('modules/HumanResources/PayrollManagement/index'),
);
const Meetings = lazy(() => import('modules/HumanResources/Meeting'));

const Vendors = lazy(() => import('modules/Accounting/vendors'));
const ChartOfAccounts = lazy(() =>
  import('modules/Accounting/chartOfaccounts'),
);
const Budget = lazy(() => import('modules/Accounting/budget'));
const Inventory = lazy(() => import('modules/Accounting/inventory/p&s'));
const FixedAssets = lazy(() =>
  import('modules/Accounting/fixedAssets/fixedAssets'),
);
const Settings = lazy(() => import('modules/Settings/general/Settings'));
const AccountSettings = lazy(() => import('modules/Settings/profile'));
const BillingSettings = lazy(() =>
  import('modules/Settings/billing/Subscription'),
);
const BillingReceipts = lazy(() => import('modules/Settings/billing/Receipt'));
const ViewBillingReceipts = lazy(() =>
  import('modules/Settings/billing/ViewReceipt'),
);
const Content = getContent(styled);

const scheme = Layout();

scheme.configureHeader((builder) => {
  builder
    .registerConfig('xs', {
      position: 'sticky',
    })
    .registerConfig('md', {
      position: 'relative', // won't stick to top when scroll down
    });
});

scheme.configureEdgeSidebar((builder) => {
  builder
    .create('unique_id', { anchor: 'left' })
    .registerTemporaryConfig('xs', {
      anchor: 'left',
      width: 'auto', // 'auto' is only valid for temporary variant
    })
    .registerPermanentConfig('md', {
      width: '261px', // px, (%, rem, em is compatible)
      collapsible: true,
      collapsedWidth: 64,
    });
});

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '14px',
        color: '#000000',
      },
    },
  },
  typography: {
    fontFamily: ['Rubik', 'sans-serif'].join(','),
    body1: { fontSize: '14px' },
  },
  palette: {
    primary: { main: '#1f53d7', dark: '#1f53d7' },
    secondary: { main: '#EB5757', dark: '#EB5757' },
    dark: { main: '#000' },
  },
});

export default function SiteLayout() {
  let { path, url } = useRouteMatch();
  const { user } = React.useContext(UserContext);
  const { organization, plan, organizationId } =
    React.useContext(OrganizationContext);
  const history = useHistory();
  const planName = plan?.planMeta?.name;
  const [isSubNotification, setIsSubNotification] = React.useState(false);
  const [isExpiredBanner, setIsExpiredBanner] = React.useState(false);
  const diff = moment(plan?.endAt).diff(new Date(), 'days');

  const routes = [
    //Banking route
    {
      title: 'Connected Banks',
      path: `${path}/connected-banks`,
      component: Banking,
      exact: true,
      permission: true,
    },
    //404 route

    // Dashboard route
    {
      title: 'Dashboard management',
      path: `${path}`,
      component: Dashboard,
      exact: true,
      permission: true,
    },
    {
      title: 'Reports',
      path: `${path}/reports`,
      component: Report,
      permission: true,
    },
    // {
    //   title: 'Employee Reports',
    //   path: `${path}/Reports/Employee_Reports`,
    //   component: Employee_Report,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Profit & Loss  Report',
    //   path: `${path}/Reports/profit_loss`,
    //   component: Profit_loss,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'General Ledger',
    //   path: `${path}/Reports/general_ledger`,
    //   component: General_ledger,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Balance Sheet',
    //   path: `${path}/Reports/balance_sheet`,
    //   component: Balance_Sheet,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Cash Flow',
    //   path: `${path}/Reports/cash_flow`,
    //   component: Cash_flow,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Trial Balance',
    //   path: `${path}/Reports/trial_balance`,
    //   component: Trial_balance,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Audit Logs',
    //   path: `${path}/Reports/audit-logs`,
    //   component: AuditLogs,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Income By Customer',
    //   path: `${path}/Reports/income_by_customer`,
    //   component: Incomebycustomer,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Budget Report',
    //   path: `${path}/Reports/budget_report`,
    //   component: Budgetreport,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Expenses By Vendor',
    //   path: `${path}/Reports/expenses_by_vendor`,
    //   component: Expensesbyvendor,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Customer Ledger',
    //   path: `${path}/Reports/customer_ledger`,
    //   component: Customerledger,
    //   exact: true,
    //   permission: true,
    // },
    // {
    //   title: 'Aged Recievable',
    //   path: `${path}/Reports/aged_recievable`,
    //   component: Agedrecievable,
    //   exact: true,
    //   permission: true,
    // },
    {
      title: 'Invoice management',
      path: `${path}/invoices`,
      component: Invoice,
      permission: true,
    },
    {
      title: 'Invoice payment',
      path: `${path}/Pay`,
      component: PayNow,
      permission: true,
    },
    {
      title: 'Customer management',
      path: `${path}/customers`,
      component: Customer,
      permission: true,
    },

    {
      title: 'Inflow management',
      path: `${path}/inflow`,
      component: Inflow,
      exact: true,
      permission: true,
    },
    {
      title: 'Outflow management',
      path: `${path}/outflow`,
      component: Outflow,
      permission: true,
    },
    {
      title: 'Journals',
      path: `${path}/journals`,
      component: Journals,
      permission: planName !== 'Simple Start',
    },

    {
      title: 'Quotation management',
      path: `${path}/quotation`,
      component: Quotation,
      permission: planName !== 'Simple Start',
    },

    {
      title: 'transactions management',
      path: `${path}/transactions`,
      component: Transactions,
      permission: true,
      // exact: true,
    },
    {
      title: 'Vendors Management',
      path: `${path}/vendors`,
      component: Vendors,
      permission: true,
    },
    {
      title: 'Chart of Account',
      path: `${path}/charts-of-accounts`,
      component: ChartOfAccounts,
      permission: true,
    },
    {
      title: 'Budget Management',
      path: `${path}/budget`,
      component: Budget,
      permission: planName === 'Premium',
    },
    {
      title: 'Inventory management',
      path: `${path}/ps`,
      component: Inventory,
      permission: true,
    },
    {
      title: 'Employees',
      path: `${path}/employees`,
      component: Employees,
      permission: true,
    },
    {
      title: 'Leave Management',
      path: `${path}/leave`,
      component: LeaveManagement,
      permission: true,
    },
    {
      title: 'Promotion Management',
      path: `${path}/promotions`,
      component: Promotion,
      exact: true,
      permission: planName !== 'Simple Start',
    },
    {
      title: 'Payslip Management',
      path: `${path}/payslip`,
      component: Payslip,
      permission: true,
    },
    {
      title: 'Suspension Management',
      path: `${path}/suspension`,
      component: Suspension,
      permission: planName !== 'Simple Start',
    },
    {
      title: 'Transfer Management',
      path: `${path}/transfer`,
      component: TransferManagement,
      permission: planName !== 'Simple Start',
    },
    {
      title: 'Performance Management',
      path: `${path}/performance-evaluation`,
      component: PerformanceManagement,
      permission: planName === 'Premium',
    },
    {
      title: 'Termination Management',
      path: `${path}/termination-and-retirement`,
      component: TerminationManagement,
      permission: true,
    },
    {
      title: 'Query Management',
      path: `${path}/query`,
      component: QueryManagement,
      permission: planName !== 'Simple Start',
    },
    {
      title: 'Payroll Management',
      path: `${path}/payroll`,
      component: PayrollManagement,
      permission: true,
    },
    {
      title: 'Memo',
      path: `${path}/memo`,
      component: Memo,
      permission: planName !== 'Simple Start',
    },
    {
      title: 'Meetings',
      path: `${path}/meeting`,
      component: Meetings,
      permission: planName === 'Premium',
    },
    {
      title: 'Fixed Assets',
      path: `${path}/fixed-assets`,
      component: FixedAssets,
      permission: planName === 'Premium',
    },
    {
      title: 'Settings',
      path: `${path}/settings`,
      component: Settings,
      permission: true,
      access: 'default',
    },
    {
      title: 'Account Settings',
      path: `${path}/account`,
      component: AccountSettings,
      permission: true,
      access: 'default',
    },
    {
      title: 'Billing Settings',
      path: `${path}/billing`,
      component: BillingSettings,
      exact: true,
      permission: true,
      access: 'default',
    },
    {
      title: 'Billing Settings',
      path: `${path}/billing/receipts`,
      component: BillingReceipts,
      exact: true,
      permission: true,
      access: 'default',
    },
    {
      title: 'Billing Settings',
      path: `${path}/billing/receipts/:id`,
      component: ViewBillingReceipts,
      exact: true,
      permission: true,
      access: 'default',
    },

    // Tax route
    // {
    //   title: 'Tax Payers Info',
    //   path: `${path}/tax_info`,
    //   component: TaxInfo,
    //   permission: planName === 'Premium',
    // },
    {
      title: 'Tax Calendar',
      path: `${path}/tax-calendar`,
      component: TaxCalender,
      permission: true,
    },
    // {
    //   title: 'Tax Filing Returns',
    //   path: `${path}/tax-returns`,
    //   component: TaxFilingReturns,
    //   permission: planName === 'Premium',
    // },

    // {
    //   title: 'Tax Calculations',
    //   path: `${path}/tax-calculation`,
    //   component: TaxCalculation,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'Tax Payments',
    //   path: `${path}/tax-payments`,
    //   component: TaxPayments,
    //   permission: planName === 'Premium',
    // },

    // {
    //   title: 'PIT Calculation',
    //   path: `${path}/PIT-calculation`,
    //   component: Pitcalculation,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'CIT Calculation',
    //   path: `${path}/CIT-calculation`,
    //   component: Citcalculation,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'PAYE Calculation',
    //   path: `${path}/Paye-calculation`,
    //   component: Payecalculation,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'WHT Calculation',
    //   path: `${path}/WHT-calculation`,
    //   component: Whtcalculation,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'CGT Calculation Individual',
    //   path: `${path}/cgt-individual`,
    //   component: Cgtcalculationindv,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'CGT Calculation Company',
    //   path: `${path}/cgt-company`,
    //   component: Cgtcalculationindv,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'VAT Calculation Goods',
    //   path: `${path}/vat-goods`,
    //   component: Vatgoods,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'VAT Calculation Services',
    //   path: `${path}/vat-services`,
    //   component: Vatservices,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    // {
    //   title: 'VAT Calculation Assets',
    //   path: `${path}/vat-assets`,
    //   component: Vatasset,
    //   exact: true,
    //   permission: planName === 'Premium',
    // },
    {
      title: 'No Subscription',
      path: `${path}/sub-info`,
      component: NoSubscription,
      exact: true,
      permission: true,
    },
    {
      title: 'Update Subscription',
      path: `${path}/subscription-expired`,
      component: UpdateSubscription,
      exact: true,
      permission: true,
    },
    {
      title: '404',
      path: '*',
      component: Notfoundpage,
      exact: true,
      permission: true,
    },
  ];

  const filterRoutes = routes.map((item) => {
    if (!plan) return { ...item, permission: true };
    if (!organization) return { ...item, permission: true };

    if (item?.access === 'default') return { ...item };
    else if (item.permission && plan?.hasEnded)
      return { ...item, component: UpdateSubscription };
    else if (item.permission && !plan?.hasEnded) return item;
    else if (!item.permission && !plan?.hasEnded)
      return { ...item, component: NoSubscription };
    else if (!item.permission && plan?.hasEnded)
      return { ...item, component: UpdateSubscription };
  });

  const LINK = `${location?.pathname}${location.search}`;
  React.useEffect(() => {
    if (!user) return;
    if (!user?.isVerified) {
      history.replace(
        `${PATHNAMES.ACCOUNT_VERIFICATION}?type=new&redirect=${LINK}`,
      );
    }
  }, [user]);

  React.useEffect(() => {
    if (!organization) return;
    if (!organization?.subscriptionMeta) history.replace(`/plans`);
  }, [organization]);

  React.useEffect(() => {
    if (organizationId) return;
    history.replace(`/organizations?redirect=${LINK}`);
  }, [organizationId]);

  React.useEffect(() => {
    if (!plan) return;
    if (Number(diff) > 0 && Number(diff) <= 10) {
      setIsSubNotification(true);
    }
    if (plan?.isExpiryMonth) setIsExpiredBanner(true);
  }, [plan]);

  const FallbackUI = () => {
    return (
      <div className="p-3 h-100 overflow-hidden">
        <div className="flex-grow-1">
          <div className="row mb-3">
            {[1, 2, 3].map((item) => (
              <div key={item} className="col-12 col-md-4">
                <Skeleton variant="rect" height={100} className="mb-3" />
              </div>
            ))}
          </div>
          <div className="row mb-3">
            {[1, 2, 3, 4].map((item) => (
              <div key={item} className="col-12 col-md-6">
                <Skeleton variant="rect" height={300} className="mb-3" />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Root scheme={scheme}>
      {({ setOpen }) => (
        <React.Fragment>
          <CssBaseline />
          <SideBar path={path} url={url} setOpen={setOpen} />

          <Content>
            {!isSubNotification ? null : (
              <div className="bg-primary text-white d-flex align-items-center">
                <div className="flex-grow-1 text-center">
                  You have {diff} day(s) left until the expiration of your
                  subscription{' '}
                  <Link
                    to="/dashboard/billing"
                    className="text-white text-decoration-underline">
                    Click here to renew your subscription
                  </Link>
                </div>
                <IconButton
                  size="small"
                  onClick={() => setIsSubNotification(false)}>
                  <Close className="text-light" />
                </IconButton>
              </div>
            )}
            {!isExpiredBanner ? null : (
              <div className="bg-primary text-white d-flex align-items-center">
                <div className="flex-grow-1 text-center">
                  Your card **** **** **** {plan?.card?.maskedPan?.slice(-4)}{' '}
                  will be expiring in {daysUntilEndOfMonth()}. To update,{' '}
                  <Link
                    to="/dashboard/billing"
                    className="text-white text-decoration-underline">
                    Click Here
                  </Link>
                </div>
                <IconButton
                  size="small"
                  onClick={() => setIsExpiredBanner(false)}>
                  <Close className="text-light" />
                </IconButton>
              </div>
            )}
            <div
              style={{
                background: '#fff',
                padding: '0rem 1rem',
              }}>
              <ThemeProvider theme={theme}>
                <Suspense fallback={<FallbackUI />}>
                  <Switch>
                    {filterRoutes.map((route, i) => (
                      <Route
                        onUpdate={() => window.scrollTo(0, 0)}
                        key={i}
                        {...route}
                      />
                    ))}
                  </Switch>
                </Suspense>
              </ThemeProvider>
            </div>
          </Content>
        </React.Fragment>
      )}
    </Root>
  );

  function daysUntilEndOfMonth() {
    const now = moment();
    const endOfMonth = moment().endOf('month');
    const daysRemaining = endOfMonth.diff(now, 'days');

    return `${daysRemaining} ${daysRemaining > 1 ? 'days' : 'day'}`;
  }
}
